import PropTypes from 'prop-types';

export const Video = ({ className, blok }) => {
  return (
    <div className={`${className} relative`}>
      <video
        data-testid="video"
        className="w-full object-cover"
        preload="auto"
        loop={true}
        autoPlay={false}
        muted={false}
        playsInline={true}
        controls={true}
        poster={blok.poster.filename}
      >
        <source type="video/mp4" src={blok.video} data-testid="video-src" />
      </video>
      {blok?.label && (
        <p className="text-xs mt-2" data-testid="video-label">
          {blok.label}
        </p>
      )}
    </div>
  );
};

Video.propTypes = {
  className: PropTypes.string,
  blok: PropTypes.shape({
    poster: PropTypes.shape({
      filename: PropTypes.string.isRequired,
    }).isRequired,
    video: PropTypes.string.isRequired,
    label: PropTypes.string,
  }).isRequired,
};

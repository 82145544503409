import { useEffect, useRef } from "react";

export const CookiePolicy = () => {
  // create ref for only running the effect once
  const initRef = useRef(false);
  useEffect(() => {
    // on mount
    const script = document.createElement("script");
    script.src = `https://consent.cookiebot.com/${process.env.REACT_APP_COOKIEBOT_ID}/cd.js`;
    script.async = true;
    const declarationTarget = document.getElementById("declarationTarget");
    if (declarationTarget && initRef.current === false) {
      declarationTarget.appendChild(script);
      initRef.current = true;
    }
  }, []);
  return (
    <div
      id="declarationTarget"
      data-testid="declarationTarget"
      className="overflow-x-clip"
    ></div>
  );
};

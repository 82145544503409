import PropTypes from 'prop-types';
import { createContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useSettings from '../hooks/useSettings';
import useNotifications from '../hooks/useNotifications';
import { DEFAULT_LANGUAGE } from '../constants/language';
import useAuthContext from '../hooks/useAuthContext';

const SettingsContext = createContext();

export const SettingsContextProvider = ({ children }) => {
  const { accessToken } = useAuthContext();

  const { i18n } = useTranslation();
  const { getSettingsFromAPI, postSettingsToAPI } = useSettings();
  const { toggleSegment } = useNotifications();

  const handleLanguageChange = (languageCode) => {
    postSettingsToAPI({ language: languageCode });
    i18n.changeLanguage(languageCode);
    window.location.pathname = `${
      languageCode === DEFAULT_LANGUAGE ? '' : languageCode
    }/settings`;
  };

  const handleNotificationChange = (segment, value) => {
    toggleSegment(segment, value);
  };

  useEffect(() => {
    async function fetchSettings() {
      const response = await getSettingsFromAPI();

      // if language is different then current locale, redirect
      if (response.language !== i18n.language) {
        i18n.changeLanguage(response?.language);
        window.location.pathname = `${
          response.language === DEFAULT_LANGUAGE ? '' : response.language
        }`;
      }
    }
    accessToken && fetchSettings();
    // We only need to run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SettingsContext.Provider
      value={{ handleLanguageChange, handleNotificationChange }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;

SettingsContextProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

import { useTranslation } from 'react-i18next';

import { Layout } from '../components/Layout';
import { NewsHeader } from '../components/NewsHeader';
import { useStoryblokProxy } from '../hooks/useStoryblokProxy';
import { Section } from '../components/Section';
import { QuickLinksContainer } from '../components/QuickLinksContainer';
import { NewsOverviewContainer } from '../components/NewsOverviewContainer';
import { PageLoading } from '../components/PageLoading';
import ErrorFallback from '../components/ErrorFallback';
import {
  PAGE_TYPE,
  NEWS_CATEGORY,
  SORT_BY_PUBLISH_DATE,
  STORY_TYPE,
  STORIES_ON_HOMEPAGE,
} from '../constants/storyblok';
import { getCategory } from '../utils/post-utils';

const HomePage = () => {
  const { t, i18n } = useTranslation();
  const {
    stories: featuredStory,
    rels: featuredStoryCat,
    isLoading: featuredStoryLoading,
    error,
  } = useStoryblokProxy({
    starts_with: STORY_TYPE.news,
    resolve_relations: NEWS_CATEGORY,
    language: i18n.language,
    sort_by: SORT_BY_PUBLISH_DATE,
    filter_query: {
      sticky: {
        is: true,
      },
    },
  });

  const { stories, rels, isLoading } = useStoryblokProxy({
    starts_with: STORY_TYPE.news,
    language: i18n.language,
    per_page: STORIES_ON_HOMEPAGE,
    resolve_relations: NEWS_CATEGORY,
    sort_by: SORT_BY_PUBLISH_DATE,
  });

  if (isLoading || featuredStoryLoading) {
    return (
      <Layout>
        <Section>
          <PageLoading />
        </Section>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <ErrorFallback
          renderReason={() => <>{t('storyblok.errorTitle')}</>}
          renderMessage={() => <p>{t('storyblok.errorMessage')}</p>}
        />
      </Layout>
    );
  }

  return (
    <Layout>
      <NewsHeader
        location={PAGE_TYPE.homepage}
        story={featuredStory?.[0] ?? stories[0]}
        category={
          (featuredStory?.[0] &&
            getCategory(
              featuredStory?.[0].content?.category,
              featuredStoryCat
            )) ||
          getCategory(stories[0].content?.category, rels)
        }
      />
      <Section>
        <NewsOverviewContainer
          stories={stories}
          exclude={featuredStory?.[0] ?? stories[0]}
          location={PAGE_TYPE.homepage}
          title={t('homepage.latestNews')}
        />
      </Section>
      <Section className="lg:pt-0">
        <QuickLinksContainer />
      </Section>
    </Layout>
  );
};
export default HomePage;

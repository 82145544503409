export const DetectKnowLocaleFromURL = {
  name: 'path-known-lang',
  lookup(options) {
    if (typeof window !== 'undefined') {
      // Split by forward slash and question mark, should result in a clean
      // locale after filtering out empty results
      const [language] = window.location.pathname
        .split(/[/?]/)
        .filter((n) => n);
      return options.knownLocales?.includes(language) ? language : undefined;
    }
  },
};

export default DetectKnowLocaleFromURL;

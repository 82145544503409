import { Spinner } from "@material-tailwind/react";

export const PageLoading = () => {
  return (
    <div
      className="flex flex-col h-full justify-center items-center"
      data-testid="page-loading"
    >
      <Spinner className="w-12 h-12" />
    </div>
  );
};

import { useEffect, useState } from "react";

/**
 * Custom hook for managing Storyblok-related page state.
 * @param {number} currentPage - Current page number.
 * @param {number} totalStories - Total number of stories.
 * @param {Array} stories - Array of fetched stories.
 * @param {number} perPage - Number of stories per page.
 * @returns {Object} - Object containing relevant state values.
 */
export const useStoryblokPageHook = (
  currentPage,
  totalStories,
  stories,
  perPage
) => {
  const [lastPage, setLastPage] = useState(0);

  // Check if we are on the last page.
  useEffect(() => {
    if (stories?.length !== 0) {
      if (totalStories <= perPage * currentPage) {
        setLastPage(currentPage);
      }
    }
  }, [stories, currentPage, totalStories, perPage]);

  // For now, we need lastPage, but we can add return values as needed.
  return {
    lastPage,
  };
};

import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export const Comments = ({ story }) => {
  // create ref for only running the effect once
  const initRef = useRef(false);
  useEffect(() => {
    // on mount
    const script = document.createElement('script');
    script.innerHTML = `(function () {
      var d = document,
      s = d.createElement("script");
      s.setAttribute("id", "commentScript");
      s.src = "${process.env.REACT_APP_CORAL_DOMAIN_NAME}/assets/js/embed.js";
      s.async = false;
      s.defer = true;
      s.onload = function () {
        Coral.createStreamEmbed({
          id: "coral_thread",
          autoRender: true,
          rootURL: "${process.env.REACT_APP_CORAL_DOMAIN_NAME}",
          storyID: "${story.uuid}",
          storyURL: "${window.location.href}"
        });
      };
      (d.head || d.body).appendChild(s);
    })();`;
    const commentThread = document.getElementById('coral_thread');
    if (commentThread && initRef.current === false) {
      commentThread.parentElement.appendChild(script);
      initRef.current = true;
    }
  }, [story]);
  return (
    <div
      className="mt-5"
      data-testid="comments-section"
      id="coral_thread"
    ></div>
  );
};

export const CommentCount = () => {
  const initRef = useRef(false);
  useEffect(() => {
    // on mount
    const script = document.createElement('script');
    script.innerHTML = `(function () {
      var d = document,
      s = d.createElement("script");
      s.setAttribute("id", "commentCountScript");
      s.src = "${process.env.REACT_APP_CORAL_DOMAIN_NAME}/assets/js/count.js";
      (d.head || d.body).appendChild(s);
    })();`;

    const commentCountTarget = document.getElementById('comment-count');
    if (commentCountTarget && initRef.current === false) {
      commentCountTarget.appendChild(script);
      initRef.current = true;
    }
  }, []);
  return (
    <span
      id="comment-count"
      className="coral-count"
      data-testid="comment-count"
      data-coral-url={window.location.href}
    ></span>
  );
};

Comments.propTypes = {
  story: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
  }).isRequired,
};

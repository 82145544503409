import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from '../i18n/Link';
import { ArrowIcon } from './icons/Icons';
import { NewsCard } from './NewsCard';
import { PAGE_TYPE, STORIES_ON_HOMEPAGE } from '../constants/storyblok';

export const NewsOverviewContainer = ({
  className,
  stories,
  exclude,
  location,
  title,
}) => {
  const { t } = useTranslation();

  if (exclude) {
    stories = stories.filter((item) => item.uuid !== exclude.uuid);
  }

  /**
   * If the number of stories on the homepage is equal to the total number of stories,
   * remove the last post, when there is no featured post.
   */
  if (STORIES_ON_HOMEPAGE === stories?.length) {
    stories.pop();
  }

  return (
    <div className={className ?? 'mt-3.5'}>
      <div className="flex justify-between items-center">
        {title && (
          <h2
            className="text-2xl lg:text-3xl text-brand-primary font-medium"
            data-testid="latest-news"
          >
            {title}
          </h2>
        )}
        {PAGE_TYPE.homepage === location && (
          <Link
            className="mt-2 text-brand-primary inline-flex font-medium items-center self-start hover:text-brand-secondary"
            title={t('homepage.seeAll')}
            to="/news"
          >
            <span className="text-sm lg:text-base mr-2">
              {t('homepage.seeAll')}
            </span>
            <ArrowIcon />
          </Link>
        )}
      </div>

      <div
        data-testid="news-container"
        className={`mt-4 ${
          (PAGE_TYPE.homepage === location ||
            PAGE_TYPE.newsDetail === location) &&
          'grid lg:grid-cols-3'
        }`}
      >
        {stories?.length ? (
          stories.map((story) => (
            <NewsCard key={story.full_slug} story={story} />
          ))
        ) : (
          <p data-testid="no-posts">{t('storyblok.noPostsFound')}</p>
        )}
      </div>
    </div>
  );
};

NewsOverviewContainer.propTypes = {
  className: PropTypes.string,
  stories: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.func,
      pop: PropTypes.func,
      map: PropTypes.func,
    })
  ).isRequired,
  exclude: PropTypes.object,
  location: PropTypes.string.isRequired,
  title: PropTypes.string,
};

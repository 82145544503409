import PropTypes from 'prop-types';
import { render } from 'storyblok-rich-text-react-renderer';
import './index.css';

export const RichText = ({ document }) => {
  return (
    <div data-testid="rich-text" className="richText lg:text-xl">
      {render(document)}
    </div>
  );
};

RichText.propTypes = {
  document: PropTypes.object.isRequired,
};

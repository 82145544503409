import React from "react";
import {
  HomeIcon,
  NewsIcon,
  PersonalIcon,
  WorkplaceIcon,
} from "../components/icons/Icons";

export const MAIN_NAV_ITEMS = [
  {
    title: "Home",
    to: "/",
    icon: <HomeIcon />,
  },
  {
    title: "News",
    to: "/news",
    icon: <NewsIcon />,
  },
  {
    title: "Workplace",
    to: "/workplace",
    icon: <WorkplaceIcon />,
  },
  {
    title: "HR",
    to: "/hr",
    icon: <PersonalIcon />,
  },
];

export const getLink = (link) => {
  if (link?.linktype !== 'story') {
    return link;
  }

  let url;

  let pagesRegex = /pages(.*)/;

  if (
    link.cached_url.match(pagesRegex) &&
    link.cached_url.match(pagesRegex).length > 0
  ) {
    // return path without pages/
    let pagesPath = link.cached_url.replace('pages/', '');
    url = pagesPath;
  } else {
    return link.cached_url;
  }

  return url;
};

export const getAppBase = () => {
  const isEnglish = window.location.pathname.startsWith('/en');
  return isEnglish ? '/en' : '';
};

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ClockIcon } from './icons/Icons';
import { formatDate } from '../utils/date-utils';
import { Image } from './Image';

export const NewsCard = ({ story }) => {
  return (
    <div className="mb-4 lg:mb-8 mr-2" data-testid="news-card">
      <Link
        data-testid="news-link"
        className="flex items-center"
        to={`/${story.full_slug}`}
      >
        <Image
          className="w-28 h-28 flex-shrink-0 mr-4 rounded-xl"
          image={story.content.featured}
        />
        <div className="flex-grow flex flex-col">
          <h2
            data-testid="news-title"
            className="font-bold text-brand-primary lg:text-xl !leading-6"
          >
            {story.content.title ?? story.name}
          </h2>
          <span className="flex items-center mt-2">
            <ClockIcon />
            <span
              data-testid="news-date"
              className="text-darkGray text-xs lg:text-sm ml-2"
            >
              {formatDate(story.first_published_at)}
            </span>
          </span>
        </div>
      </Link>
    </div>
  );
};

NewsCard.propTypes = {
  story: PropTypes.shape({
    name: PropTypes.string.isRequired,
    full_slug: PropTypes.string.isRequired,
    first_published_at: PropTypes.string.isRequired,
    content: PropTypes.shape({
      featured: PropTypes.object.isRequired,
      title: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

import { useEffect } from 'react';
import { NEWS_SEGMENT, SUBSCRIBED } from '../constants/notifications';

let subscribed = false;

export const useNotifications = () => {
  const getSubscription = () => {
    // eslint-disable-next-line eqeqeq
    return subscribed == SUBSCRIBED;
  };

  const toggleSegment = (segment, value) => {
    if (value !== false) {
      // eslint-disable-next-line no-undef
      PushAlertCo.forceSubscribe();
      // eslint-disable-next-line no-undef
      (pushalertbyiw = window.pushalertbyiw || []).push([
        'addToSegment',
        segment,
      ]);
    } else {
      // eslint-disable-next-line no-undef
      (pushalertbyiw = window.pushalertbyiw || []).push([
        'removeFromSegment',
        segment,
      ]);
      // eslint-disable-next-line no-undef
      PushAlertCo.unsubscribe();
    }
  };

  useEffect(() => {
    // Define the onPAReady function
    const onPAReady = () => {
      // eslint-disable-next-line no-undef
      const userInfo = PushAlertCo.getSubsInfo();
      subscribed = userInfo?.status || false;
    };

    // Initialize pushalertbyiw and set the onReady event handler
    // eslint-disable-next-line no-undef
    (window.pushalertbyiw = window.pushalertbyiw || []).push([
      'onReady',
      onPAReady,
    ]);
  }, []);

  useEffect(() => {
    // Define the onPASuccess function
    const onPASuccess = (result) => {
      // False means user just subscribed
      if (!result.alreadySubscribed) {
        // eslint-disable-next-line no-undef
        (window.pushalertbyiw = window.pushalertbyiw || []).push([
          'addToSegment',
          NEWS_SEGMENT,
        ]);
      }
    };

    // Initialize pushalertbyiw and set the onSuccess event handler
    // eslint-disable-next-line no-undef
    (window.pushalertbyiw = window.pushalertbyiw || []).push([
      'onSuccess',
      onPASuccess,
    ]);
  }, []);

  return {
    getSubscription,
    toggleSegment,
  };
};

export default useNotifications;

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ArrowIcon } from './icons/Icons';
import { getLink } from '../utils/link-utils';

export const QuickLinks = ({ title, to }) => {
  return (
    <Link
      data-testid="quick-link"
      className="py-2 px-3 rounded-full text-brand-primary inline-flex font-medium items-center self-start bg-light hover:bg-gray-400"
      title={title}
      to={getLink(to)}
    >
      <span className="text-sm lg:text-base mr-2">{title}</span>
      <ArrowIcon />
    </Link>
  );
};

QuickLinks.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.object.isRequired,
};

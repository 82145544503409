import { NavLink } from '../../i18n/Link';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MoreIcon } from '../icons/Icons';
import { SubNavigation } from './SubNavigation';
import { MAIN_NAV_ITEMS } from '../../constants/mainNav';

export const Navigation = () => {
  const [openNav, setOpenNav] = useState(false);
  const { t } = useTranslation();

  const { pathname } = useLocation();
  useEffect(() => {
    setOpenNav(false); // Close the navigation panel
  }, [pathname]);

  const handleResize = () => {
    if (window.innerWidth >= 960) {
      setOpenNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', openNav);
  }, [openNav]);

  const navList = MAIN_NAV_ITEMS.map((link) => (
    <NavLink
      exact
      key={link.title}
      to={link.to}
      className="inline-flex flex-col items-center justify-center group"
    >
      <span className="mb-1 text-white group-hover:text-brand-secondary">
        {link.icon}
      </span>
      <span className="text-xs text-white group-hover:text-brand-secondary">
        {t(`navigation.${link.title.toLowerCase()}`)}
      </span>
    </NavLink>
  ));

  const moreButton = (
    <button
      data-testid="open-subnav-button"
      className={`inline-flex flex-col items-center justify-center group ${
        openNav ? 'active' : ''
      }`}
      title={t('navigation.toggle')}
      onClick={() => setOpenNav(!openNav)}
    >
      <MoreIcon className="mb-1 text-white group-hover:text-brand-secondary" />
      <span className="text-xs text-white group-hover:text-brand-secondary">
        {t('navigation.more')}
      </span>
    </button>
  );

  return (
    <>
      <SubNavigation openNav={openNav} />
      <nav
        className="fixed lg:relative bottom-0 lg:bottom-[unset] lg:order-1 left-0 z-50 w-full pt-3 bg-brand-primary rounded-t-[10px] lg:rounded-none pb-safe"
        data-testid="navigation"
      >
        <div className="grid h-full grid-flow-col justify-stretch mx-auto lg:max-w-screen-xl grid-cols-5">
          {navList}
          {moreButton}
        </div>
      </nav>
    </>
  );
};

export const DEFAULT_LANGUAGE = "lt";

export const LANGUAGES = [
  {
    code: "lt",
    name: "Lietuvių",
  },
  {
    code: "en",
    name: "English",
  },
];

export function formatDate(dateString = "") {
  if (dateString === "") {
    return dateString;
  }
  const output = new Date(dateString);
  return output.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '../components/Image';
import { Video } from '../components/Video';
import { Button } from '../components/Button';
import { RichText } from '../components/RichText';
import { CONTENT_TYPE } from '../constants/storyblok';

export const PageBody = ({ className, blok }) => {
  const { body } = blok;

  return (
    <div className={className} data-testid="page-body">
      {body?.map((section) => {
        return (
          <React.Fragment key={section._uid}>
            {CONTENT_TYPE.text === section.component && (
              <RichText data-testid="richtext" document={section.text} />
            )}
            {CONTENT_TYPE.image === section.component && (
              <Image
                image={section.image}
                label={section.label}
                className="w-full max-w-4xl mx-auto my-6 lg:my-16"
              />
            )}
            {CONTENT_TYPE.video === section.component && (
              <Video
                blok={section}
                className="w-full max-w-4xl mx-auto my-6 lg:my-16"
              />
            )}
            {CONTENT_TYPE.button === section.component && (
              <Button blok={section} />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

PageBody.propTypes = {
  className: PropTypes.string,
  blok: PropTypes.shape({
    body: PropTypes.arrayOf(
      PropTypes.shape({
        map: PropTypes.func,
      }).isRequired
    ),
  }).isRequired,
};

import { useTranslation } from 'react-i18next';
import { STORY_TYPE } from '../constants/storyblok';
import { useStoryblokProxy } from '../hooks/useStoryblokProxy';
import { QuickLinks } from './QuickLinks';
import { PageLoading } from './PageLoading';
import ErrorFallback from './ErrorFallback';

export const QuickLinksContainer = () => {
  const { t, i18n } = useTranslation();

  const { stories, isLoading, error } = useStoryblokProxy({
    starts_with: STORY_TYPE.quicklinks,
    per_page: 1,
    language: i18n.language,
  });

  if (isLoading) {
    return <PageLoading />;
  }

  if (error) {
    return (
      <ErrorFallback
        renderReason={() => <>{t('storyblok.errorTitle')}</>}
        renderMessage={() => (
          <p data-testid="error-text">{t('storyblok.errorMessage')}</p>
        )}
      />
    );
  }

  const quicklinks = stories[0].content.navigation;

  return (
    <>
      <h2
        className="text-2xl lg:text-3xl text-brand-primary font-medium"
        data-testid="quick-links"
      >
        {t('homepage.quickLinks')}
      </h2>
      <div className="mt-4 flex flex-wrap gap-3">
        {quicklinks?.length ? (
          quicklinks.map((quicklink) => (
            <QuickLinks
              to={quicklink.link}
              key={quicklink.link.cached_url}
              title={quicklink.title}
            />
          ))
        ) : (
          <p data-testid="no-posts-found">{t('storyblok.noPostsFound')}</p>
        )}
      </div>
    </>
  );
};

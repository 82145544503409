import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@material-tailwind/react';
import { useHistory } from 'react-router-dom';
import useAuthContext from '../hooks/useAuthContext';
import { getAppBase } from '../utils/link-utils';
import { EyeClosedIcon, EyeOpenIcon } from '../components/icons/Icons';

const Login = () => {
  const { loginAction, error, accessToken } = useAuthContext();
  const { t } = useTranslation();
  const history = useHistory();

  const [input, setInput] = useState({
    username: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();

    if (input.username !== '' && input.password !== '') {
      loginAction(input);
      setLoading(true);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    error && setLoading(false);
  }, [error]);

  useEffect(() => {
    // If user is already logged in, return to homepage
    accessToken && history.push(`${getAppBase()}/`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section
      data-testid="login"
      className="bg-brand-primary h-screen flex items-center justify-center"
    >
      <div className="w-full lg:max-w-screen-md flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
        <div className="bg-white rounded-lg">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <img
              className="w-36 h-36 mx-auto"
              src="/logo512.png"
              alt="WeMove logo"
            />
            <h1 className="text-xl text-center font-bold text-gray-900 md:text-2xl">
              {t('login.signInTitle')}
            </h1>
            {error && (
              <div
                className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50"
                role="alert"
              >
                <span className="font-medium">{error}</span>
              </div>
            )}

            <form className="space-y-4 md:space-y-6" onSubmit={handleLogin}>
              <div>
                <label
                  data-testid="username"
                  htmlFor="username"
                  className="block mb-2 font-medium text-gray-900"
                >
                  {t('login.enterUsername')}
                </label>
                <input
                  data-testid="username-input"
                  type="text"
                  id="username"
                  name="username"
                  placeholder="username@pon.com"
                  autoComplete="username"
                  aria-invalid="false"
                  required
                  onChange={handleInput}
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                />
              </div>
              <div class="relative">
                <div class="absolute inset-y-0 right-0 flex items-center px-2 top-8">
                  <input
                    class="hidden"
                    id="password-toggle"
                    type="checkbox"
                    onChange={() => setShowPassword((prev) => !prev)}
                  />
                  <label
                    class="fill-gray-600 cursor-pointer"
                    for="password-toggle"
                  >
                    {showPassword ? <EyeClosedIcon /> : <EyeOpenIcon />}
                  </label>
                </div>
                <label
                  data-testid="password"
                  htmlFor="password"
                  className="block mb-2 font-medium text-gray-900"
                >
                  {t('login.enterPassword')}
                </label>
                <input
                  data-testid="password-input"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  autoComplete="current-password"
                  aria-invalid="false"
                  required
                  onChange={handleInput}
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                />
              </div>
              <div>
                <a
                  href="https://pon.okta.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('login.forgotPassword')}
                </a>
              </div>
              <button
                data-testid="login-button"
                type="submit"
                className="my-2 py-3 px-5 rounded-full inline-flex self-start font-medium items-center group bg-brand-primary hover:bg-brand-secondary"
                title={'Sign in to WeMove'}
              >
                {loading ? (
                  <Spinner />
                ) : (
                  <span className="text-white group-hover:text-white">
                    {t('login.signInButton')}
                  </span>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;

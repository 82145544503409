import PropTypes from 'prop-types';
import { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LOCALSTORAGE_TOKEN_KEY } from '../constants/login';

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const localStorageTokenItem =
    localStorage.getItem(LOCALSTORAGE_TOKEN_KEY) || false;

  const { t } = useTranslation();
  const [accessToken, setAccessToken] = useState(
    JSON.parse(localStorageTokenItem)?.accessToken || false
  );
  const [error, setError] = useState(false);
  const history = useHistory();

  const expDate = JSON.parse(localStorageTokenItem)?.expiry;
  // Set expirationTime one minute before actual expiration time.
  const expirationTime = expDate * 1000 - 60000;

  if (Date.now() >= expirationTime) {
    localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY);
    history.push('/login');
  }

  const loginAction = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MICROSERVICE_URL}/login`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
          credentials: 'include',
        }
      );

      if (!response.ok) {
        setError(t('login.error'));
        return;
      }

      const res = await response.json();

      const token = {
        accessToken: res.accessToken,
        expiry: new Date(res.expiresAt).getTime() / 1000,
      };

      localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, JSON.stringify(token));
      setAccessToken(token.accessToken);
      window.location.replace('/');
    } catch (err) {
      setError(t('login.error'));
    }
  };

  return (
    <AuthContext.Provider value={{ accessToken, loginAction, error }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

AuthContextProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import useAuthContext from './hooks/useAuthContext';
import { getAppBase } from './utils/link-utils';

const PrivateRoute = ({ children }) => {
  const { accessToken } = useAuthContext();
  const history = useHistory();

  if (!accessToken) {
    history.push(`${getAppBase()}/login`);
  }
  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default PrivateRoute;

export const NEWS_CATEGORY = "category";

export const PAGE_TYPE = {
  homepage: "home",
  newsOverview: "newsOverview",
  newsDetail: "newsDetail",
  static: "static",
  cookiePolicy: "cookiePolicy",
};

export const STORY_TYPE = {
  news: "news",
  quicklinks: "quicklinks",
  settings: "settings",
};

export const CONTENT_TYPE = {
  text: "text",
  video: "video",
  image: "image",
  button: "button",
};

export const STORIES_ON_HOMEPAGE = 4;

export const STORIES_PER_PAGE = 3;

export const STORIES_PER_PAGE_OVERVIEW = 20;

export const SORT_BY_PUBLISH_DATE = "first_published_at:desc";

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../components/Layout';
import { Section } from '../components/Section';
import { NewsOverviewContainer } from '../components/NewsOverviewContainer';
import { SearchIcon } from '../components/icons/Icons';
import { PageHeader } from '../components/PageHeader';
import { PageChangeButtons } from './PageChangeButtons';
import { useStoryblokPageHook } from '../hooks/usePageHook';
import { STORIES_PER_PAGE_OVERVIEW } from '../constants/storyblok';

export const NewsOverviewPage = ({
  stories,
  location,
  setCurrentPage,
  currentPage,
  totalStories,
  searchTerm,
  setSearchTerm,
}) => {
  const { t } = useTranslation();

  // Set local state for searchterm.
  const [term, setTerm] = useState(searchTerm);

  const { lastPage } = useStoryblokPageHook(
    currentPage,
    totalStories,
    stories,
    STORIES_PER_PAGE_OVERVIEW
  );

  useEffect(() => {
    // To prevent multiple API calls, we set the searchTerm with the local term after 500 ms.
    const timeoutId = setTimeout(() => {
      setSearchTerm(term);
    }, 500);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term]);

  const handleSearch = (event) => {
    setTerm(event.target.value);
  };

  return (
    <Layout>
      <PageHeader title={t('news.title')} subtitle={t('news.subTitle')}>
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <SearchIcon />
          </div>
          <input
            type="text"
            className="ps-10 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder={t('news.search')}
            value={term}
            onChange={handleSearch}
          />
        </div>
      </PageHeader>
      <Section>
        <NewsOverviewContainer stories={stories} location={location} />
        <PageChangeButtons
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          lastPage={lastPage}
        />
      </Section>
    </Layout>
  );
};

NewsOverviewPage.propTypes = {
  stories: PropTypes.array.isRequired,
  location: PropTypes.string.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalStories: PropTypes.number.isRequired,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func.isRequired,
};

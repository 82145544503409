import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Section } from '../components/Section';
import { Layout } from '../components/Layout';
import { PageHeader } from '../components/PageHeader';
import { PageBody } from '../storyblok/PageBody';
import { CookiePolicy } from '../components/CookiePolicy';

export const DetailPage = ({ story }) => {
  const location = useLocation();

  return (
    <Layout>
      <PageHeader
        title={story.content.title ?? story.name}
        subtitle={story.content?.subtitle}
      />
      <Section>
        <PageBody blok={story.content} className="flex flex-col" />
        {location.pathname.includes('cookie-policy') && <CookiePolicy />}
      </Section>
    </Layout>
  );
};

DetailPage.propTypes = {
  story: PropTypes.shape({
    content: PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
    }).isRequired,
    name: PropTypes.string,
  }).isRequired,
};

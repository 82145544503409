import PropTypes from 'prop-types';
import { Drawer } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { useStoryblokProxy } from '../../hooks/useStoryblokProxy';
import { getLink } from '../../utils/link-utils';
import { NavLink } from '../../i18n/Link';
import { STORY_TYPE } from '../../constants/storyblok';
import { DEFAULT_LANGUAGE } from '../../constants/language';

export const SubNavigation = ({ openNav }) => {
  const { t, i18n } = useTranslation();
  const { stories, isLoading, error } = useStoryblokProxy({
    starts_with: STORY_TYPE.settings,
    per_page: 1,
    language: i18n.language,
  });

  return (
    <>
      {!isLoading && !error && (
        <Drawer
          className="shadow-none bg-brand-primary pb-[160px] px-5 z-10 transform-gpu flex items-end lg:items-start lg:justify-center lg:text-center lg:pt-32"
          overlayProps={{
            className: 'bg-transparent backdrop-blur-none shadow-none',
          }}
          overlay={false}
          open={openNav}
          size={window.innerWidth}
        >
          <div className="flex flex-col">
            {stories[0]?.content.navigation?.map((item) => {
              const buttonProps =
                item.link.linktype === 'story'
                  ? {
                      to: `${
                        i18n.language === DEFAULT_LANGUAGE ? '/' : ''
                      }${getLink(item.link)}`,
                      target: item.link.target,
                    }
                  : {
                      href: item.link.cached_url,
                      target: '_blank',
                      rel: 'noreferrer',
                    };

              const Component = item.link.linktype === 'story' ? NavLink : 'a';

              return (
                <Component
                  key={item.link.cached_url}
                  className="text-brand-secondary text-[26px] [&:not(:first-child)]:mt-4 font-medium"
                  {...buttonProps}
                >
                  {item.title}
                </Component>
              );
            })}

            <NavLink
              to="/settings"
              className="text-brand-secondary text-[26px] [&:not(:first-child)]:mt-4 font-medium"
            >
              {t('settings.title')}
            </NavLink>
          </div>
        </Drawer>
      )}
      {error && (
        <Drawer
          className="shadow-none bg-brand-primary pb-[160px] px-5 z-10 transform-gpu flex items-end"
          overlayProps={{
            className: 'bg-transparent backdrop-blur-none shadow-none',
          }}
          overlay={false}
          open={openNav}
          size={window.innerWidth}
        >
          <></>
        </Drawer>
      )}
    </>
  );
};

SubNavigation.propTypes = {
  openNav: PropTypes.bool.isRequired,
};

import PropTypes from 'prop-types';
import { ArrowIcon } from '../components/icons/Icons';
import { useTranslation } from 'react-i18next';

export const PageChangeButtons = (props) => {
  const { currentPage, setCurrentPage, lastPage } = props;

  const { t } = useTranslation();
  return (
    <div className="flex justify-between mt-4">
      {currentPage > 1 && (
        <button
          className="py-2 px-3 rounded-full text-brand-primary inline-flex font-medium items-center self-start hover:text-brand-secondary bg-light justify-self-start"
          onClick={() => setCurrentPage((prevState) => --prevState)}
          data-testid="previous-page-button"
        >
          <ArrowIcon className="rotate-180 mr-2" />
          <span className="text-sm lg:text-base">
            {t('navigation.previous')}
          </span>
        </button>
      )}
      {currentPage !== lastPage && (
        <button
          className="py-2 px-3 rounded-full text-brand-primary inline-flex font-medium items-center self-start hover:text-brand-secondary bg-light ml-auto"
          onClick={() => setCurrentPage((prevState) => ++prevState)}
          data-testid="next-page-button"
        >
          <span className="text-sm lg:text-base">{t('navigation.next')}</span>
          <ArrowIcon className="ml-2" />
        </button>
      )}
    </div>
  );
};

PageChangeButtons.propTypes = {
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  lastPage: PropTypes.number.isRequired,
};

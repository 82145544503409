import PropTypes from 'prop-types';

export const Toggle = ({ title, value, handleToggle }) => (
  <>
    <span className="text-brand-primary">{title}</span>
    <label
      className="relative inline-flex items-center cursor-pointer"
      htmlFor={title.replace(/\s+/g, '-').toLowerCase()}
    >
      <input
        data-testid={title.replace(/\s+/g, '-').toLowerCase()}
        id={title.replace(/\s+/g, '-').toLowerCase()}
        type="checkbox"
        checked={value}
        name={title}
        className="sr-only peer relative"
        onChange={() => handleToggle(!value)}
      />
      <div className="w-14 h-8 bg-light peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-brand-secondary rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:start-[4px] after:bg-white after:border-light after:border after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-brand-primary"></div>
    </label>
  </>
);

Toggle.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
};

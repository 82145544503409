import { useQuery } from '@tanstack/react-query';
import { useState, useEffect, useCallback, useMemo } from 'react';
import useAuthContext from './useAuthContext';

export const getArticleLikes = async (uuid, options) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_MICROSERVICE_URL}/api/news/${uuid}/likes`,
      options
    );
    if (!response.ok) {
      throw new Error('Failed to fetch likes');
    }
    const data = await response.json();

    return data.count;
  } catch (error) {
    throw new Error(`Error: ${error.message}`);
  }
};

export const getArticleLikeByUser = async (uuid, options) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_MICROSERVICE_URL}/api/news/${uuid}/userliked`,
      options
    );
    if (!response.ok) {
      throw new Error('Failed to fetch likes');
    }
    const data = await response.json();

    return data.like;
  } catch (error) {
    throw new Error(`Error: ${error.message}`);
  }
};

export const useLikes = (uuid) => {
  const [isLikedByUser, setIsLikedByUser] = useState(false);
  const [data, setData] = useState([]);

  const { accessToken } = useAuthContext();

  const options = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };
  }, [accessToken]);

  const { data: likedByUser } = useQuery(['articleLikeByUser', uuid], () =>
    getArticleLikeByUser(uuid, options)
  );

  const fetchLikes = useCallback(async () => {
    const response = await getArticleLikes(uuid, options);
    setData(response);
  }, [uuid, options]);

  const postLike = async (isLikedByUser) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MICROSERVICE_URL}/api/news/${uuid}/userliked`,
        {
          method: 'POST',
          headers: {
            ...options.headers,
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ like: !isLikedByUser }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to post user liked');
      }

      fetchLikes();
      setIsLikedByUser(!isLikedByUser);
    } catch (error) {
      throw new Error(`Error: ${error.message}`);
    }
  };

  useEffect(() => {
    fetchLikes();
  }, [fetchLikes]);

  useEffect(() => {
    setIsLikedByUser(likedByUser);
  }, [likedByUser]);

  return { likes: data, isLikedByUser, postLike };
};

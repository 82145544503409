import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import i18n from '../i18n';
import { Link } from '../i18n/Link';

import { Section } from '../components/Section';
import { Layout } from '../components/Layout';
import { NewsHeader } from '../components/NewsHeader';
import { Pill } from '../components/Pill';
import { NewsOverviewContainer } from '../components/NewsOverviewContainer';
import { ArrowIcon } from '../components/icons/Icons';
import { Comments, CommentCount } from '../components/Comments';

import { PageBody } from '../storyblok/PageBody';
import {
  PAGE_TYPE,
  STORY_TYPE,
  SORT_BY_PUBLISH_DATE,
  STORIES_PER_PAGE,
} from '../constants/storyblok';
import { PILL_ICON_TYPE } from '../constants/pillIcon';

import { useStoryblokProxy } from '../hooks/useStoryblokProxy';
import { useLikes } from '../hooks/useLikes';

const COMMENTS_SECTION_ID = 'coralCommentsSection';

export const NewsDetailPage = ({ story, category }) => {
  const { t } = useTranslation();

  const { likes, isLikedByUser, postLike } = useLikes(story?.uuid);
  const [likesCount, setLikesCount] = useState(likes);

  useEffect(() => {
    setLikesCount(likes);
  }, [likes]);

  useEffect(() => {
    const title = story.content.title ?? story.name;
    // Get the existing og:title meta tag
    const ogTitleMetaTag = document.querySelector('meta[property="og:title"]');

    // Update the value if the tag already exists
    if (ogTitleMetaTag) {
      ogTitleMetaTag.setAttribute('content', title);
    } else {
      // Create the og:title meta tag if it doesn't exist
      const newOgTitleMetaTag = document.createElement('meta');
      newOgTitleMetaTag.setAttribute('property', 'og:title');
      newOgTitleMetaTag.setAttribute('content', title);
      document.head.appendChild(newOgTitleMetaTag);
    }

    document.title = title;
  }, [story]);

  // Related news
  const { stories: relatedNews } = useStoryblokProxy({
    starts_with: STORY_TYPE.news,
    per_page: STORIES_PER_PAGE,
    language: i18n.language,
    excluding_ids: story.id,
    sort_by: SORT_BY_PUBLISH_DATE,
    filter_query: {
      category: {
        // Relation based on category
        in: story.content.category,
      },
    },
  });

  return (
    <Layout>
      <NewsHeader
        location={PAGE_TYPE.newsDetail}
        story={story}
        category={category}
      />
      <Section>
        <div className="flex flex-wrap gap-3">
          <Pill
            data-testid="pill-likes"
            text={t('news.likes', { count: likesCount })}
            icon={PILL_ICON_TYPE.like}
            onClick={() => postLike(isLikedByUser)}
            isLikedByUser={isLikedByUser}
          />
          <Pill
            data-testid="pill-comments"
            icon={PILL_ICON_TYPE.comment}
            as="a"
            href={`#${COMMENTS_SECTION_ID}`}
          >
            <CommentCount />
          </Pill>
        </div>
        <PageBody blok={story.content} className="mt-5 lg:mt-12" />
        <div id={COMMENTS_SECTION_ID} className="mt-5">
          <h2 className="text-2xl lg:text-3xl text-brand-primary font-medium">
            {t('news.commentsTitle')}
          </h2>
          <div id={COMMENTS_SECTION_ID}>
            <Comments story={story} />
          </div>
        </div>
        {relatedNews?.length && (
          <>
            <NewsOverviewContainer
              className="mt-5 lg:mt-12"
              stories={relatedNews}
              location={PAGE_TYPE.newsDetail}
              title={t('news.relatedPostsTitle')}
            />
            <Link
              className="mt-6 lg:mt-8 text-brand-primary inline-flex font-medium items-center self-start hover:text-brand-secondary"
              title={t('navigation.backToOverview')}
              to={'/news'}
            >
              <ArrowIcon className="rotate-180 mr-2" />
              <span className="text-base mr-2 font-medium">
                {t('navigation.backToOverview')}
              </span>
            </Link>
          </>
        )}
      </Section>
    </Layout>
  );
};

NewsDetailPage.propTypes = {
  story: PropTypes.shape({
    id: PropTypes.number.isRequired,
    uuid: PropTypes.string.isRequired,
    content: PropTypes.shape({
      category: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
    full_slug: PropTypes.string.isRequired,
  }).isRequired,
  category: PropTypes.object.isRequired,
};
